<template>
	<div class="flex bg-gray-50">
		<div class="w-72 bg-white z-10 border-r fixed h-full">
			<div class="h-16 flex items-center text-xl font-semibold leading-tight pl-6 mb-6">MP Management</div>
			<Menu />
		</div>
		<div class="flex-1 flex flex-col min-h-screen ml-72">
			<page-header>
				<template #actions>
					<div class="flex items-center">
						<w-language-select />
					</div>
					
					<div class="items-center flex px-2">
						<!-- :label="tokenType == 'SuperAdmin' ? 'Super Admin' : 'Account Manager'" -->
						<w-dropdown no-border
							label="Account"
							:options="[{ label: 'Disconnect', icon: 'logout', to: { name: 'auth-logout' } }]"
							>
						</w-dropdown>
						<!-- <p>{{tokenType == 'SuperAdmin' ? 'Super Admin' : 'Account Manager'}}</p> -->
					</div>
				</template>
			</page-header>
			<div class="py-10 sm:py-6 px-6 w-full flex-1 mt-16">
				<div class="page-content h-full max-w-6xl mx-auto">
					<router-view/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Menu from '@/components/Menu'
	import PageHeader from '@/components/common/PageHeader.vue'
	import { mapActions, mapState } from 'vuex'
	export default {
		name: 'default',
		components: {
			Menu,
			'page-header': PageHeader
		},
		computed: {
			...mapState({
				locales: state => state.i18n.locales,
				locale: state => state.i18n.locale,
				tokenType: state => state.auth.tokenType
			})
		},
		methods: {
			...mapActions([
				'setLang'
			]),
			changeLang (val) {
				this.$i18n.locale = val
				this.setLang(val)
			}
		}
	}
</script>
<style>
	.lang-cn {
		background-image: url('../../static/img/icons/chinese.png');
	}
	.lang-en {
		background-image: url('../../static/img/icons/english.png');
	}
</style>