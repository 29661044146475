<template>
	<w-navbar>
		<template v-for="(item, index) in items.filter(i => i.permission)">
			<w-navbar-item :label="$t(item.label)" :to="item.to" :exact="item.exact" :key="index">
				<template #prepend>
					<w-icon size="6" :file="item.icon"></w-icon>
				</template>
				<template #badge>
					<!-- <w-badge warning v-if="item.badge">{{ item.badge }}</w-badge> -->
					<w-tag warning v-if="item.badge">{{ item.badge }}</w-tag>
				</template>
			</w-navbar-item>
		</template>
	</w-navbar>
</template>
<script>
	import { mapState } from 'vuex'
	export default {
		computed: {
			...mapState({
				tokenType: state => state.auth.tokenType,
				failedAuditCount: state => state.deployment.failedAuditCount
			}),
			items() {
				return [
					{ label: 'MENU.HOME', icon: 'home', to: {name: 'index'}, exact: true, permission: true },
					{ label: 'MENU.MINI_PROGRAM_LIST', icon: 'view-list', to: {name: 'mini-programs'}, badge: this.failedAuditCount, permission: true },
					{ label: 'MENU.TEMPLATES', icon: 'collection', to: {name: 'templates-templates'}, permission: (this.tokenType === 'SuperAdmin') },
					{ label: 'MENU.DOMAIN_SETTING', icon: 'cog', to: {name: 'domain-setting'}, permission: true },
					{ label: 'MENU.DASHBOARD', icon: 'QRcode', to: {name: 'dashboard'}, permission: true },
					{ label: 'MENU.MINI_PROGRAM_PATH', icon: 'link', to: {name: 'mini-program-path'}, permission: true },
					{ label: 'MENU.QUOTA', icon: 'information-circle', to: {name: 'quota'}, permission: (this.tokenType === 'SuperAdmin') }
				]
			}
		},
	}
</script>